import * as S from "./style";
import React, { useCallback, useEffect, useState } from "react";
import { useCampaign } from "../../context/CampaignContext";
import { AxiosRequestNextGame } from "../../domain/AxiosRequestNextGame";
import { makeNextGameUseCase } from '../../team-game/main/helper/make-next-game-use-case'

const nextGameUseCase = makeNextGameUseCase()

interface INextMatch {
  name: string;
  logo: string;
}

function NextMatch(): React.ReactElement {
  const [nextMatch, setNextMatch] = useState<INextMatch>();
  const { campaignData } = useCampaign();

  const nextGame = useCallback(async () => {
    const result = await nextGameUseCase.handle({ teamId: 155 })
    
    setNextMatch({
      name: result.title,
      logo: result.image,
    })
  }, [])

  useEffect(() => {
    nextGame()
  }, [nextMatch]);

  return (
    <S.NextMatchContainer>
      <S.NextMatchTitle>Próximo Jogo</S.NextMatchTitle>
      <S.Players>
        <S.NextMatchPlayer>
          <img src={campaignData.teamShield} alt="escudo-casa" className="home" />
          <span>{campaignData.teamName}</span>
        </S.NextMatchPlayer>
        <span>X</span>
        {nextMatch ? (
          <>
            <S.NextMatchPlayer>
              <img src={nextMatch.logo} alt="escudo-opposing-team" />
              <span>{nextMatch.name}</span>
            </S.NextMatchPlayer>
          </>
        ) : (
          <div>Estamos atualizando</div>
        )}
      </S.Players>
    </S.NextMatchContainer>
  );
}

export default NextMatch;
